import config_local from "../../configuration/local_config.json";

/*-------------------------Actual APIs-------------------------*/
const reckitt_domain = config_local.LOCAL ? config_local.reckitt_domain : "";
const backendApi = "/api";
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const authUrl = `${reckitt_domain}${backendApi}/isauthenticated`;
const userInfoUrl = `${reckitt_domain}${backendApi}/userinfo?post_sign_in_url=https%3A%2F%2F`;
const authoriseUrl = `${reckitt_domain}${backendApi}/isauthorised?post_sign_in_url=https%3A%2F%2F`;
const appUserRoleUrl = `${reckitt_domain}${backendApi}/appuserrole`;
const appsUrl = `${backendApi}/apps`;
const configsUrl = `${backendApi}/configs`;
const countryUrl = `${backendApi}/countries`;
const brandUrl = `${backendApi}/brands`;
const segmentUrl = `${backendApi}/segments`;
const globalParamsUrl = `${backendApi}/globalParams`;
const globalRegionsUrl = `${backendApi}/globalRegions`;
const modelsUrl = `${backendApi}/models`;
const datasetsUrl = `${backendApi}/datasets`;
const categoryUrl = `${backendApi}/categories`;
const campaignsUrl = `${backendApi}/campaigns`;
const configUrl = `${backendApi}/config`;
// const configRunUrl = `${backendApi}/config/run`;
const configRunUrl = `${backendApi}/v2/config/run`;
const runUrl = `${backendApi}/runs`;
const notificationUrl = `${backendApi}/notifications`;
const downloadRunUrl = `${backendApi}/run/download`;
const nestedRunLevelsUrl = `${backendApi}/nestedRunLevels`;
const audienceNameTargetRunUrl = `${backendApi}/audienceName`;
const nestedRunLevelDriversUrl = `${backendApi}/nestedRunLevelDrivers`;
const veritasAppUrl = "https://veritas.rbone.net/";
const histogramUrl = `${backendApi}/run/histogram`;
const subBrandsUrl = `${backendApi}/subBrands`;
const subSegmentsUrl = `${backendApi}/subSegments`;
const mediaSubBrandsUrl = `${backendApi}/mediaSubBrands`;
const mediaSubSegmentsUrl = `${backendApi}/mediaSubSegments`;
const mediaSegmentsUrl = `${backendApi}/mediaSegments`;
const nestedLevelsDriversUrl = `${backendApi}/nestedLevelsDrivers`;
const appAccessDetailsUrl = `${backendApi}/appAccessDetails`;
const downloadResourcesUrl = `${backendApi}/downloadResources`;
const sharedConfigsUrl = `${backendApi}/sharedConfigs`;
const listUsersUrl = `${backendApi}/users`;
const shareConfigUrl = `${backendApi}/shareConfig`;
const appResorcesUrl = `${backendApi}/appResources`;
const datasetDetailsUrl = `${backendApi}/datasetDetails`;
const submitFormUrl = `${backendApi}/triggerEmail`;
const userUrl = `${backendApi}/admin/user`;
const groupUrl = `${backendApi}/admin/group`;
const allUsersUrl = `${backendApi}/admin/globalUsers`;
const listAdminUsersUrl = `${backendApi}/admin/users`;
const listFeatureList = `${backendApi}/admin/appFeatures`;
const scheduleConfigurl = `${backendApi}/scheduleJobs`;
const skuDetailsUrl = `${backendApi}/skuDetails`;
const bookmarkUrl = `${backendApi}/bookmark`;
const getTagsUrl = `${backendApi}/getTags`;
const uploadValidateFilesUrl = `${backendApi}/uploadValidate`;
const downloadUrl = `${backendApi}/download`;
const controlRegionUrl = `${backendApi}/controlRegion`;
const sharedRunsUrl = `${backendApi}/sharedRuns`;
const shareRunUrl = `${backendApi}/shareRun`;
const saveConfigForSharedRun = `${backendApi}/sharedRunConfig`;
const editTags = `${backendApi}/tags`;
const configAndSchedule = `${backendApi}/configAndSchedule`;
const dataQualityReports = `${backendApi}/dataQualityReports`;
const mergeDataset = `${backendApi}/channelDatasets`;
const optimalRunUrl = `${backendApi}/optimalRuns`;
const validateSignOut = `${backendApi}/validateSignOut`;
const v2RunsUrl = `${backendApi}/v2/runs`;
const cancelDag = `${backendApi}/cancel-dag`;
const validateRun = `${backendApi}/validation-dashboard`;
const viewDashboard = `${backendApi}/view-dashboard`;
const salesRegionsUrl = `${backendApi}/salesRegions`;
const mediaRegionsUrl = `${backendApi}/mediaRegions`;
// const v2RunsUrl =
//   "https://run.mocky.io/v3/81808f57-be0b-492d-986e-a8d578948ca7";
const v2ConfigsUrl = `${backendApi}/v2/configs`;
const automlAlgorithm = `${backendApi}/automl-algorithms`;
const raiseATicketUrl =
  "https://rbassist.service-now.com/ssp?id=sc_cat_item&table=sc_cat_item&sys_id=237f3d1683cd4214b7b671dfeeaad35d";
const messages = {
  authenticatingUserMessage: "Authenticating user",
  loginPrimaryText: "Log in to find out more!",
  UnauthorisedMessage:
    "You don't have access to this app. Please contact support for the access.",
  noApps: "No applications available",
  noAppsAccess: "You no longer have access to this app",
  noDatasources: "No datasets available",
  configSuccessSave: "Configuration saved successfully.",
  configSuccessUpdate: "Configuration updated successfully.",
  configSuccessDelete: "Configuration deleted successfully",
  configDeleteRequest: "Requesting to delete configuration",
  configSuccessRun: "Configuration saved and run successfully",
  runActive: "Configuration running...",
  noRuns: "No runs available under this category.",
  runDeleteRequest: "Requesting run delete...",
  runCancelRequest: "Requesting cancellation on Airflow",
  runDeleteSuccess: "Run deleted successfully",
  runCancelSuccess:
    "Your cancellation request to Airflow has been submitted successfully.",
  noConfigs: "No configurations available for your selections.",
  noRecentConfigs: "No Recent configurations available",
  noAdminUsersFound: "No Users found for your selections.",
  noAdminGroupsFound: "No Groups found for your selections.",
  nodatasetParameters: "No Parameters available for your selections.",
  noOptions: "No options available",
  noDocuments: "No Document found",
  noRuns: "No runs available under this category.",
  noALgo: "No algorithm available under this category.",
  noNames: "No names available",
  noLevels: "No levels available",
  noDrivers: "No drivers available",
  confirmDelete: "Are you sure you want to delete?",
  confirmCancel: "Are you sure you want to cancel the execution on Airflow?",
  runFailed: "Sorry, your job was not successful.",
  runQueued: "Your job is in the queue.",
  runRunning: "Your job is running.",
  runSuccess: "Your job was successful!",
  runSuccessInfo:
    "While we are working on a feature to show you a dashboard with your run outputs, you could download your output files by clicking on the Download button at top right of this pane.",
  noDatasets: "No datasets available for your selections.",
  noData: "No data available",
  invalidNestedRun: "Cannot add AutoML nested run without ",
  invalidBudgetOnlyRun:
    "Cannot add Regression Budget, budget only run without ",
  oneModelSelectedError: "At least one model should be enabled",
  multipleModelSelectedError: "Please enable only one model for Schedule runs.",
  invalidConfigName:
    "Configuration names can only have letters, numbers, hyphens and underscores.",
  invalidRunName:
    "Run names can only have letters, numbers, hyphens and underscores.",
  validConfigAdd: "Valid configuration. Requesting inclusion...",
  validConfigUpdate: "Valid configuration. Requesting update...",
  validateRegionGranModelEnable:
    "Cannot add configuration with region type National and Diff&Diff/RegressionBudget/SalesUplift model enabled. Please disable those models for a successful run.",
  validateRegionGranModelEnableForMMT:
    "Cannot add configuration with region type Regional and MMT model enabled. Please disable the model for a successful run.",
  nodataForCategory: "No datasets available",
  diffNDiffCampaign: "Cannot add Diff&Diff without all campaign selected.",
  regressionBudgetCombinations:
    "Cannot add Regression Budget without combination set selected.",
  volumeFieldAvailableMessageDiffAndDiff:
    "Please select volume field in Diff in Diff model.",
  volumeFieldAvailableMessage: "Please select Columns To Be Enforced field in.",
  salesUpliftTestGroupParameters:
    "Cannot add Sales Uplift without test group parameters set selected.",
  salesUpliftTestRegionsTestGroupParametersMismatch:
    "Test Region sets and Test Group Parameter sets don’t match in Sales Uplift Model.",
  autoMlCampaign: "Cannot add AutoML without all campaign selected.",
  noCategories: "No categories",
  noPeriodicity: "No periodicity",
  nestedRunOnly: "Disable all other models if AutoML nested run is selected.",
  historicWeekValidation:
    "Historic Week cannot be empty, 0, negative, decimal or greater than 300",
  dateSelectedValidation:
    "Start Date or End Date in date range cannot be empty",
  invalidDateSelection:
    "Global End date should be greater than Global Start date.",
  weekValidation: "Please enter weeks",
  emptyConfigName: "Please enter the Configuration Name",
  testControlNonEmpty: "Test & Control regions need to be non-empty",
  emptyBrandName: "Please select the Brand",
  combinationError: "Please enter valid inputs.",
  productionBannerMessage:
    "We will soon be decommissioning all user data from the UAT environment. Please access the Production environment by visiting",
  segmentHotWarmMessage:
    "Hot segment can't be equal or greater than warm segment",
  segmentWarmColdMessage:
    "Warm segment can't be equal or greater than cold segment",
  noRegionsAvailable: "No regions selected",
  requestSubmitMessage:
    "Thank you for your request! Our team will reach out to you shortly for further nformation. Have a great day ahead.",
  tryAgainMsg: "Some error occured. Please try again!",
  disclaimerMessage:
    "Disclaimer: Start, End and Last Updated Date are based on country only. Brand granularity is not considered.",
  salesRangeMessage:
    "0 -100 percentile based on sales KPI where 0 represents region(s) with lowest sales and 100 represents region(s) with highest sales.",
  somethingWentWrong: "Something went wrong, please contact support.",
  pilotDateValidation:
    "Pilot Start Date or Pilot End Date in date in AutoML cannot be empty",
  pilotDatesInRangevalidation:
    "Pilot Dates for AutoML should lie between the selected global date range.",
  pilotStartDateEndDateValidation:
    "Pilot End date should be greater than Pilot Start date in AutoML.",
  pilotDateValidationAutoMlBudget:
    "Pilot Start Date or Pilot End Date in date in AutoML Budget cannot be empty",
  pilotDatesInRangevalidationAutoMlBudget:
    "Pilot Dates for AutoML Budget should lie after the selected global end date.",
  pilotStartDateEndDateValidationAutoMlBudget:
    "Pilot End date should be greater than Pilot Start date in AutoML Budget.",
  pilotDatesInRangevalidationSalesUplift:
    "Pilot End Date for Sales Uplift should not lie after the selected Global End Date",
  configSharingSuccess:
    "Configuration shared successfully with selected users.",
  configSharingFailure: "Configuration Sharing Failed. Please try again.",
  runSharingSuccess: "Run shared successfully with selected users.",
  runSharingFailure: "Run Sharing Failed. Please try again.",
  noOperationForShareConfig: "No Operation selected. Please select.",
  RequestingToCloneConfig: "Requesting to add a new configuration...",
  RequestingToShareConfig: "Requesting to share configuration...",
  RequestingToShareRun: "Requesting to share run",
  RequestingToSaveConfigForRun:
    "Requesting to create configuration for this run",
  configCreatedSuccessfullyForRun: "Configuration created successfully",
  configCreationFailedForNestedRunForMROI:
    "Configuration creation is not supported for nested or budget only run",
  configCreationFailedForManualRunForMROI:
    "Configuration creation is not supported for Manual run",
  loginPrimaryText: "AI Deployment Environment",
  loginSecondaryText_1: "One place to run your AI models across functions.",
  loginSecondaryText_2: "Log in to find out more!",
  uploadFileSizeMessage: "Allowed maximum size is 10MB.",
  allowedTagsLimitMessage: "Only 5 tags are allowed.",
  duplicateTagFoundMessage: "Tag is already added.",
  fileNameSpacesMessage:
    "The filename can not contain whitespace. Please rename the file.",
  statusMessage400:
    "Note: Multiple Categories With Same Category Name Are Found",
  statusMessage400a: "Note: Duplicate Categories Are Found",
  duplicateName:
    "Configuration Name Already Exists, Please Use a Different Name",
  retailMarginPriceMessage:
    "Retail Margin Price is also known as the Average Net Price. It is the price at which Reckitt sells its products to retailers. It is not the shelf price. It can be calculated by multiplying NR margin by retail (shelf) price.",
  darkCellMessage: "Cell with no media activation.",
  salesCoverageMessage:
    "Sales Coverage is the percentage of a brand (or product) total sales represented by the selected data source. Sales Coverage equal to 1 means that the selected data source covers all brand sales from a total market.",
  weeksDiffIconMessage:
    "Number of weeks between the last day for which there is data and today.",
  runCancel:
    "Your cancellation request to Airflow has been submitted successfully.",
  infoForShareDashboard:
    "Before share dashboard please make sure this run is optimal.",
  requestInAMessage:
    "This dashboard is successfully shared to I&A team for review.You can check the status of the review on this page.",
  requestValidationError: `This run does not have a pilot start date/brand.
    Country, brand and pilot start date form an identifier for a run`,
  collinearVariableSelect:
    "Occurrence of two or more inter-correlated independent variables",
  allParameter:
    '"ALL" is now treated as an actual value in the Product Attribute dropdowns',
};

const hardCoded = {
  homeLocation: "https://admin-aide-dev.rbone.net",
  appName: "MROI",
  nativeAppsList: ["MROI"],
  nativeAppIdList: ["MROI"],
  mroiAppId: "MROI",
  runRunningId: "running",
  runPrevRunsId: "previous_runs",
  runQueuedId: "queued",
  runSuccess: "Success",
  runFailed: "Failed",
  runQueued: "Queued",
  runRunning: "Running",
  runCancel: "Cancelled",
  runSchedule: "Scheduled",
  downloadInputType: "input",
  downloadOutputType: "output",
  autoMlNestedId: "AUTOML_NESTED",
  mmtId: "MMT",
  regBudId: "REGRESSION_BUDGET",
  salesUpliftId: "SALES_UPLIFT",
  diffDiffId: "DIFF&DIFF",
  autoMlId: "AUTOML",
  autoMlBudgetId: "AUTOML_BUDGET",
  autoMlIds: ["AUTOML", "AUTOML_BUDGET"],
  autoMlRbtAndSUIds: [
    "AUTOML",
    "AUTOML_BUDGET",
    "SALES_UPLIFT",
    "REGRESSION_BUDGET",
  ],
  uploadFileExcludedModel: [
    "REGRESSION_BUDGET",
    "SALES_UPLIFT",
    "AUTOML",
    "AUTOML_BUDGET",
  ],
  testAndControlCellModels: [
    "REGRESSION_BUDGET",
    "SALES_UPLIFT",
    "AUTOML",
    "AUTOML_BUDGET",
  ],
  mmlIds: ["MMT"],
  pilotRunId: "pilot",
  adstockRunId: "adstock",
  nestedRunId: "nested",
  rbtRunId: "rbt",
  budgetId: "budget_only",
  initialRunId: "initial",
  targetRunId: "target",
  uploadAudienceRunId: "upload_audience",
  automlRunKey: "run_type",
  rbtRunKey: "rbt_run_type",
  specificId: "specific",
  neilsenCompId: "NIELSEN_COMPETITOR",
  neilsenNonCompId: "NIELSEN_NONCOMP",
  tvId: "TV",
  googleAdsIds: ["GOOGLEADS_SEARCH", "GOOGLEADS"],
  nestedRunReqFieldsList: [
    { pilot_run_id: "pilot run id" },
    { algorithm: "algorithm" },
  ],
  nestedRunRegionsKeysList: ["level_name", "nested_fields"],
  budgetOnlyRunReqFieldsList: [{ rbt_run_id: "run id" }],
  configurableUiElementTypes: [
    "radio_button",
    "inputbox_number",
    "inputbox_text",
    "dropdown",
  ],
  historicWeek: "historic_week_data",
  dateRange: "date_range",
  datasetSalesType: "SALES",
  datasetNeilsenType: "CATEGORY",
  datasetMediaType: "MEDIA",
  radioDatasetList: ["SALES", "CATEGORY"],
  selectClearAllDatasetList: ["MEDIA", "MACRO"],
  combinationsId: "combinations",
  campaignsArrayId: "campaigns",
  combinationsSalesId: "test_group_parameters",
  salesUpliftSets: 15,
  regressionBudgetSets: 10,
  allSelection: "Select ALL",
  oldAllSelection: "ALL",
  // homeLocation: "/home",
  mappingStructure: {
    country: "name",
    brand: "id",
    archetype: "id",
  },
  localSource: "LOCAL",
  automlCustomDataset: ["SALES", "CATEGORY"],
  mandatoryTargetVariable: [
    "sales_value",
    "sales_units",
    "sales_volume",
    "sold_units",
    "retail_sales",
  ],
  adstockMappingForDisplay: ["sl_limit", "su_limit"],
  defaultVertical: "hygiene",
  mulipleRunType: ["rbt_run_type", "run_type"],
  adminRole: "admin",
  viewRunsCategory: ["Running", "Queued", "Success", "Failed", "Cancelled"],
  adStockMinLimit: 0,
  adStockMaxLimit: 6,
  kpiVariableList: ["base", "mandatory", "monotonic"],
  volume_kpis: ["price_per_volume_promo", "price_per_volume_regular"],
  units_kpis: ["price_per_unit_promo", "price_per_unit_regular"],
  avgPriceDependentKpiList: [
    "price_per_unit_regular",
    "price_per_volume_regular",
    "price_per_unit_promo",
    "price_per_volume_promo",
  ],
  avgPriceDependentKpiListForSuAndRbt: [
    "price_per_unit_regular",
    "price_per_volume_regular",
  ],
  resetAllExclude: [
    "average_price",
    "sales_units",
    "sales_value",
    "sales_volume",
    "sold_units",
    "retail_sales",
  ],
  competitorArr: ["NIELSEN_NONCOMP", "TV"],
  competitorTVNielsenArr: ["NIELSEN_COMPETITOR", "TV_COMPETITOR"],
  nielsenDatasets: ["NIELSEN_NONCOMP", "LOCAL_NIELSEN_NONCOMP"],
  localNielsen: "LOCAL_NIELSEN_NONCOMP",
  topNCompetitor: ["TV"],
  testAndLearnArr: [
    "SALES_UPLIFT",
    "DIFF&DIFF",
    "AUTOML",
    "AUTOML_BUDGET",
    "REGRESSION_BUDGET",
  ],
  shareBusinessDashBoardModels: ["SALES_UPLIFT", "AUTOML"],
  tabsForScheduleToggle: ["myconfigurations", "starred", "recent"],
  stepperInRbt: ["expected_roas", "sales_coverage"],
  kpiTypePrimary: "primary",
  kpiTypeSecondary: "secondary",
  kpiTypeInformative: "informative",
  manualKpiARR: ["MMT", "SALES_UPLIFT", "REGRESSION_BUDGET"],
  rbtAndSuIds: ["SALES_UPLIFT", "REGRESSION_BUDGET"],
  correlation_threshold_country: ["UNITED STATES"],
};

const api = {
  appsUrl,
  configsUrl,
  globalRegionsUrl,
  globalParamsUrl,
  modelsUrl,
  datasetsUrl,
  categoryUrl,
  campaignsUrl,
  countryUrl,
  brandUrl,
  segmentUrl,
  configUrl,
  configRunUrl,
  authUrl,
  signInUrl,
  signOutUrl,
  runUrl,
  notificationUrl,
  nestedRunLevelsUrl,
  audienceNameTargetRunUrl,
  downloadRunUrl,
  nestedRunLevelDriversUrl,
  veritasAppUrl,
  histogramUrl,
  subBrandsUrl,
  subSegmentsUrl,
  nestedLevelsDriversUrl,
  appAccessDetailsUrl,
  sharedConfigsUrl,
  listUsersUrl,
  shareConfigUrl,
  appResorcesUrl,
  downloadResourcesUrl,
  datasetDetailsUrl,
  submitFormUrl,
  userUrl,
  groupUrl,
  allUsersUrl,
  listAdminUsersUrl,
  listFeatureList,
  scheduleConfigurl,
  skuDetailsUrl,
  bookmarkUrl,
  getTagsUrl,
  uploadValidateFilesUrl,
  downloadUrl,
  controlRegionUrl,
  sharedRunsUrl,
  shareRunUrl,
  userInfoUrl,
  authoriseUrl,
  appUserRoleUrl,
  saveConfigForSharedRun,
  editTags,
  configAndSchedule,
  dataQualityReports,
  mergeDataset,
  optimalRunUrl,
  validateSignOut,
  v2RunsUrl,
  v2ConfigsUrl,
  cancelDag,
  validateRun,
  viewDashboard,
  mediaSegmentsUrl,
  mediaSubBrandsUrl,
  mediaSubSegmentsUrl,
  salesRegionsUrl,
  mediaRegionsUrl,
  automlAlgorithm,
  raiseATicketUrl,
};

const authorizationMessages = {
  noAccessMessage: "You don't have access to this app. Please contact support.",
};

const config = {
  footerText: "Powered by RBOne",
  localStorageReduxStoreName: "rbAideReduxStore",
  campaignDupErrorNote: "Cannot add same campaign with multiple date range",
  campaignMinErrorNote: "Please select a min date",
  campaignMaxErrorNote: "Please select a max date",
  campaignSelectErrorNote: "Select a campaign",
  inputNumberValidationError: "Input number out of range",
  inputTextValidationError: "Please enter value",
  campaignStartEndDateError: "End date should be greater than Start date",
  salesRangeError: "Percentile Range must be less than 50",
  api,
  messages,
  hardCoded,
  autoHideDuration: 10000,
  fetchNotificationDuration: 300000,
  runDataInterval: 300000,
  runsLimit: 1000,
  pilotWeek: 2,
  configSuccessSaveMessage: "Configuration saved successfully.",
  configSuccessUpdateMessage: "Configuration updated successfully.",
  configSuccessDeleteMessage: "Configuration deleted successfully",
  configDeleteRequestMessage: "Requesting to delete configuration...",
  configSuccessRunMessage: "Configuration saved and run successfully",
  scheduleConfigRequestMessage: "Requesting configuration to be scheduled...",
  scheduleConfigUpdateRequestMessage:
    "Requesting schedule configuration update...",
  scheduleConfigSuccessMessage: "Configuration scheduled successfully",
  scheduleConfigUpdateSuccess:
    "Schedule of the configuration updated successfully",
  scheduleDeleteRequestMessage:
    "Requesting to delete scheduled configuration...",
  scheduleSuccessDeleteMessage:
    "Schedule of the configuration deleted successfully",
  authorizationMessages,
};

export { config };
